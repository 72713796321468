.subtitle-slots > * {
  display: none;
}

.subtitle-bottom {
  width: 10px;
  opacity: 0;
  z-index: 999;
  background-image: url("narration-straight-corners.a6e02bd3.svg");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  padding: 2em;
  font-family: sriracha;
  position: absolute;
  top: 10vh;
}

.subtitle-slots {
  z-index: 999;
}

.subtitle {
  width: 365px;
  box-sizing: border-box;
  opacity: 0;
  z-index: 999;
  text-transform: uppercase;
  letter-spacing: -.01em;
  background-image: url("narration-straight-corners.a6e02bd3.svg");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  flex-flow: row;
  justify-content: center;
  align-items: center;
  padding: 20px 25px;
  font-family: sriracha;
  font-size: 1.125rem;
  line-height: 140%;
  display: flex;
  position: absolute;
  top: 5%;
}

#full-width-narration {
  width: 100%;
  max-width: 1200px;
  position: absolute;
  bottom: 5%;
}

#main-narration {
  width: 80%;
  min-width: 400px;
  text-align: left;
  max-width: 1200px;
  top: unset;
  opacity: 0;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  margin-left: auto;
  margin-right: auto;
  padding: 1em 2em;
  font-size: 1.6rem;
  position: fixed;
  bottom: 50px;
}

#bottom-narration-slot-1, #bottom-narration-slot-2, #bottom-narration-slot-3, #bottom-narration-slot-4 {
  bottom: 3.5%;
  top: unset;
}

.thought-bubble-container {
  height: 190px;
  width: 402px;
  opacity: 1;
  z-index: 2000;
  position: absolute;
  top: 5%;
  left: 3485px;
}

.thought-bubble {
  opacity: 1;
  width: 402px;
  min-height: 110px;
  opacity: 0;
  background-image: url("thought-bubble_336x90.0d9d14f9.svg");
  background-repeat: no-repeat;
  background-size: cover;
  background-origin: border-box;
  top: 0;
  left: 0;
}

.bubble-round {
  height: auto;
  opacity: 0;
  background-image: url("bubble-round.ca00d990.svg");
  background-repeat: no-repeat;
  background-size: cover;
  background-origin: border-box;
  position: absolute;
}

#round1 {
  width: 22px;
  height: 22px;
  bottom: -4px;
  left: 290px;
}

#round2 {
  width: 18px;
  height: 18px;
  bottom: -30px;
  left: 270px;
}

#round3 {
  width: 10px;
  height: 10px;
  bottom: -40px;
  left: 250px;
}

#trm-round1 {
  width: 22px;
  height: 22px;
  bottom: -4px;
  left: 290px;
}

#trm-round2 {
  width: 18px;
  height: 18px;
  bottom: -30px;
  left: 270px;
}

#trm-round3 {
  width: 10px;
  height: 10px;
  bottom: -40px;
  left: 250px;
}

#tl-round1 {
  width: 22px;
  height: 22px;
  top: -17px;
  left: -24px;
}

#tl-round2 {
  width: 18px;
  height: 18px;
  top: -29px;
  left: -53px;
}

#tl-round3 {
  width: 10px;
  height: 10px;
  top: -30px;
  left: -85px;
}

.speechbubble {
  width: 365px;
  height: 153px;
  background-image: url("speech-bubble.97dfc14a.svg");
  background-repeat: no-repeat;
  background-size: cover;
  background-origin: border-box;
  padding: 15px 8px 45px 31px;
  overflow: visible;
}

.speechbubble > div.english:before, .speechbubble > div.teReo:before {
  content: "“ ";
  margin-left: -.5em;
}

.speechbubble > div.english:after, .speechbubble > div.teReo:after {
  content: " ”";
}

.subtitle-button-container {
  z-index: 999;
  margin: 40px;
  position: fixed;
  top: 20px;
  right: 40px;
}

.subtitle-button {
  height: 2rem;
  width: 4rem;
  z-index: 999;
  margin: 0;
  padding: 0;
}

.subtitle-button img {
  width: 100%;
  height: 100%;
}

.english:not(.active), .teReo:not(.active) {
  display: none;
}

.voiceover-btn {
  width: 24px;
  height: 24px;
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url("play-btn-24.7661f2a3.svg");
  background-repeat: no-repeat;
  background-size: contain;
  border: 2px solid #000;
  border-radius: 2px;
  text-decoration: none;
  display: block;
  position: absolute;
  bottom: -32px;
  right: 2px;
  box-shadow: 0 0 4px #00000080, 0 0 6px #043775;
}

.voiceover-btn.sound-loading {
  filter: contrast(.4);
  cursor: wait;
}

.voiceover-btn.playing {
  filter: contrast(.7);
}

.speechbubble .voiceover-btn {
  background-color: #fff;
  bottom: -3px;
  right: 0;
}

.narration .voiceover-btn {
  background-color: #fff0c4;
}

.narration.bottom .voiceover-btn {
  right: 2px;
  bottom: unset;
  top: -32px;
}

.thought-bubble .voiceover-btn {
  background-color: #fff;
  border-radius: 34%;
  bottom: -28px;
  right: 1px;
}

.voiceover-btn:hover {
  transition: all .3s;
  box-shadow: 0 0 10px #fff, 0 0 10px #fff, 0 0 10px #fff, 0 0 10px #fff;
}

.voiceover-btn:focus, .voiceover-btn:active {
  -webkit-appearance: none;
  -moz-appearance: none;
  outline: 0 auto #0000;
}

.corner-br, .corner-br > * {
  transform: rotateY(180deg);
}

.corner-tr, .corner-tr > * {
  transform: rotateX(180deg)rotateY(180deg);
}

.corner-tl, .corner-tl > * {
  transform: rotateX(180deg);
}

/*# sourceMappingURL=index.33c655ae.css.map */
