.subtitle-slots > * {
  display: none;
}

.subtitle-bottom{
  /* OLD - delete if not needed once whole scene is finished */
  font-family: sriracha;  
    position: absolute;
    padding: 2em;
    width: 10px;
    opacity: 0;
    z-index: 999;
    top: 10vh;
    background-image:url(../assets/speech-bubbles/narration-straight-corners.svg);
    background-repeat:no-repeat;
    background-size: 100% 100%;      
  }
 
  
  .subtitle-slots {
    z-index: 999;
  }

  .subtitle {
    font-family: sriracha;
    width: 365px;
    /* height:110px; */
    padding: 20px 25px;
    line-height: 140%;  
    box-sizing: border-box;
    top: 5%;  
    opacity: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    z-index: 999;
    text-transform: uppercase;
    letter-spacing: -0.01em;   
    position: absolute;
    background-image: url(../assets/speech-bubbles/narration-straight-corners.svg);
    background-repeat: no-repeat;
    background-size: 100% 100%; 
    font-size: 1.125rem;
    

  }
  
  
  #full-width-narration{
    width:100%;
    max-width:1200px;
    position:absolute;
    bottom:5%;
  }
  /* OLD - delete if not needed once whole scene is finished */
  #main-narration {
    width: 80%;
    min-width:400px;
    text-align:left;
    max-width: 1200px;
    position: fixed; 
    background-repeat: no-repeat;
    background-size: 100% 100%;
    margin-left: auto;
    margin-right: auto;
    /* unset the top inherited from the subtitle class */
    top: unset;   
    bottom: 50px;
    opacity:0;
    font-size: 1.6rem;
    padding: 1em 2em 1em 2em;
  }
  
  
  /* first narration box - first set */
  #top-narration-slot-1{
    
  }
  
  .english, .teReo{
  
  
  }
  #bottom-narration-slot-1, #bottom-narration-slot-2, #bottom-narration-slot-3, #bottom-narration-slot-4 {
    bottom:3.5%;
    top:unset;
  }
  
  .thought-bubble-container{
    
    height: 190px;
    width: 402px;
    position: absolute;
    top: 5%;
    left: 3485px; /* TODO: remove this from here */
    opacity: 1;
    z-index: 2000;
  }
  .thought-bubble{
    background-image:url(../assets/speech-bubbles/thought-bubble_336x90.svg); 
    background-repeat: no-repeat;
    background-size: cover;
    background-origin: border-box;        
    opacity:1;
    width:402px;
    min-height:110px;
    top:0;  
    left:0;
    opacity: 0;
  }
  
  .bubble-round{
    position:absolute;  
    height:auto; 
    opacity:0;
    background-image:url(../assets/speech-bubbles/bubble-round.svg); 
    background-repeat: no-repeat;
    background-size: cover;
    background-origin: border-box; 
  }
  
  #round1{
    width: 22px;
      height: 22px;
      left: 290px;
      bottom: -4px;
  }
  #round2{
    width: 18px;
      height: 18px;
      left: 270px;
      bottom: -30px;
  }
  #round3{
    width: 10px;
      height: 10px;
      left: 250px;
      bottom: -40px;
  }
  #trm-round1{
    width: 22px;
      height: 22px;
      left: 290px;
      bottom: -4px;
  }
  #trm-round2{
    width: 18px;
      height: 18px;
      left: 270px;
      bottom: -30px;
  }
  #trm-round3{
    width: 10px;
      height: 10px;
      left: 250px;
      bottom: -40px;
  }

  #tl-round1{
    width: 22px;
      height: 22px;
      left: -24px;
      top: -17px;
  }
  #tl-round2{
    width: 18px;
      height: 18px;
  left: -53px;
  top: -29px;
  }
  #tl-round3{
    width: 10px;
      height: 10px;
      left: -85px;
      top: -30px;
  }

/* subtitles.0c1ae80f.css | http://localhost:1234/subtitles.0c1ae80f.css */



  
  
  .speechbubble {  
    background-image:url(../assets/speech-bubbles/speech-bubble.svg);  
    width: 365px;
    height: 153px;
    background-repeat: no-repeat;
    background-size: cover;
    background-origin: border-box;
    overflow: visible;   
    padding: 15px 8px 45px 31px;
  }

  .speechbubble>div.english::before, .speechbubble>div.teReo::before{
    content:'“ ';
    margin-left:-0.5em;
  }
  .speechbubble>div.english::after, .speechbubble>div.teReo::after{
    content:' ”';    
  }
  /* .speechbubble div.english, .speechbubble div.teReo{
    margin-top:-28px;
  } */
  
  .subtitle-button-container {
    position: fixed;
    margin: 40px;
    top: 20px;
    right: 40px;
    z-index: 999;
  }
  
  .subtitle-button {
    height: 2rem;
    width: 4rem;
    z-index: 999;
    margin: 0;
    padding: 0;
  }
  
  .subtitle-button img {
    width: 100%;
    height: 100%;
  }

  .subtitle .active {
    /* text-align-last: justify; */
    /* margin: auto; */
  }
  
  .english:not(.active) {
    display: none;
  }
  
  .teReo:not(.active) {
    display: none;
  }

  .voiceover-btn{    
    width: 24px;
    height: 24px;
    border-radius: 2px;
    background-image:url('../icons/play-btn-24.svg');
    background-size: contain;
    background-repeat: no-repeat;
    position: absolute;   
    display: block;
    right: 2px;
    bottom: -32px;
    border: 2px solid #000000;
    cursor:pointer;
    box-shadow: 0 0px 4px 0 rgba(0, 0, 0, 0.5), 0 0px 6px 0 rgb(4, 55, 117);
    -webkit-appearance: none;
    -moz-appearance: none;
    text-decoration: none;
  }

  .voiceover-btn.sound-loading {
    filter: contrast(0.4);
    cursor: wait;
  }
  .voiceover-btn.playing {
    filter: contrast(0.7);
  }

  .speechbubble .voiceover-btn {
    background-color:#ffffff;
    right: 0px;
    bottom: -3px;
  }

  .narration .voiceover-btn {
    background-color:#fff0c4;
  }

  .narration.bottom .voiceover-btn{
    right: 2px;
    bottom: unset;
    top: -32px;
   }

  .thought-bubble .voiceover-btn {
    background-color:#ffffff;
    right: 1px;
    bottom: -28px;
    border-radius: 34%;
  }

  .voiceover-btn:hover{
    box-shadow: 0 0px 10px 0 rgb(255, 255, 255), 0 0px 10px 0 rgb(255, 255, 255), 0 0px 10px 0 rgb(255, 255, 255), 0 0px 10px 0 rgb(255, 255, 255);   
    transition: 0.3s;
  }

  .voiceover-btn:focus, .voiceover-btn:active  {    
    -webkit-appearance: none;
    outline: none;
    -moz-appearance: none;
    outline: transparent auto 0px;
}

.corner-br, .corner-br > * {
  transform: rotateY(180deg)
}

.corner-tr, .corner-tr > * {
  transform: rotateX(180deg) rotateY(180deg)
}

.corner-tl, .corner-tl > * {
  transform: rotateX(180deg)
}